<template>
  <Page title="Thank you for your order">
    <div class="c-thankYou">
      <BannerImageAndTitle
        :background="[
          {
            url: $t('widgets.thankYou.header.background[0].url'),
            format: $t('widgets.thankYou.header.background[0].format'),
            altText: $t('widgets.thankYou.header.background[0].altText'),
          },
          {
            url: $t('widgets.thankYou.header.background[1].url'),
            format: $t('widgets.thankYou.header.background[1].format'),
          },
        ]"
        :banner-title="$t('widgets.thankYou.header.title')"
        :banner-sub-title="$t('widgets.thankYou.header.subtitle')"
      />
      <div class="c-thankYou__gridContainer">
        <section class="c-thankYou__splitContainer -secbg h-pv-20">
          <div class="c-thankYou__splitContainerSpace">
            <div class="-size">
              <div
                class="h-pb-20"
                v-if="isDeliveryOrder"
              >
                <div class="-flexBox -reverseCol">
                  <Typography
                    v-text="$t('widgets.thankYou.order.title')"
                    variant="body1"
                    class="-primary h-uppercase"
                  />
                  <Typography
                    v-html="
                      `${$t('widgets.thankYou.order.orderNumber')} <b>#${orderDetails.id}</b>`"
                    variant="body1"
                    class="-fontBook -flexBoxId"
                  />
                </div>
                <Typography
                  v-text="relativeDate(orderDetails)"
                  variant="body1"
                  class="-fontBold"
                />
              </div>
              <div class="h-pv-20">
                <div class="-flexBox -reverseCol">
                  <Typography
                    v-text="$t('widgets.thankYou.order.deliveredTo')"
                    variant="body1"
                    class="-primary h-uppercase"
                  />
                  <Typography
                    v-html="
                      `${$t('widgets.thankYou.order.orderNumber')} <b>#${orderDetails.id}</b>`"
                    variant="body1"
                    class="-fontBook -flexBoxId"
                    v-if="!isDeliveryOrder"
                  />
                </div>

                <Typography
                  v-text="orderDetails.name"
                  variant="body1"
                  class="-fontBold"
                />
                <Typography
                  v-if="isDeliveryOrder && orderDetails.customerAddress"
                  v-text="customerAddress(orderDetails.customerAddress)"
                  variant="body1"
                />
              </div>
            </div>
          </div>
          <div class="divider h-mv-20" />
          <div class="c-thankYou__splitContainerSpace">
            <div class="-size">
              <div class="h-pv-20">
                <OrderSummary
                  :order-details="orderDetails"
                  summary-class="-primary h-uppercase"
                />
              </div>
            </div>
          </div>
        </section>
        <section
          class="c-thankYou__splitContainer h-pv-20"
        >
          <div
            class="c-thankYou__splitContainerSpace"
            v-show="fidelityActive && isAuthenticated"
          >
            <div class="-size">
              <Typography
                v-text="$t('widgets.thankYou.order.loyaltyPoint.text')"
                variant="body1"
                class="-primary h-uppercase"
              />
              <div class="-flexBox">
                <Typography
                  v-text="$t('widgets.thankYou.order.loyaltyPoint.earned')"
                  variant="body2"
                  class="-fontBold -flexBoxText"
                />
                <Typography
                  v-html="toPoint(orderDetails.earned_points | 0)"
                  variant="body1"
                  class="-fontBold"
                />
              </div>
              <div class="-flexBox">
                <Typography
                  v-text="$t('widgets.thankYou.order.loyaltyPoint.used')"
                  variant="body2"
                  class="-fontBold -flexBoxText"
                />
                <Typography
                  v-html="toPoint(orderDetails.used_points | 0)"
                  variant="body1"
                  class="-fontBold"
                />
              </div>
              <div class="-flexBox">
                <Typography
                  v-text="$t('widgets.thankYou.order.loyaltyPoint.total')"
                  variant="body2"
                  class="-fontBold -flexBoxText"
                />
                <Typography
                  v-html="toPoint(orderDetails.customer_points | 0)"
                  variant="body1"
                  class="-fontBold"
                />
              </div>
              <Typography
                v-text="$t('widgets.thankYou.order.loyaltyPoint.subText')"
                variant="body1"
                class="-muted"
              />
            </div>
          </div>
          <div
            class="c-thankYou__splitContainerSpace h-pv-20"
            v-if="orderDetails.shop"
          >
            <div class="-size">
              <Typography
                v-text="$t('widgets.thankYou.order.restaurant.title')"
                variant="body1"
                class="-primary h-uppercase"
              />
              <Typography
                v-text="orderDetails.shop.name"
                variant="body1"
                class="-fontBold"
              />
              <Typography
                v-text="customerAddress(orderDetails.shop)"
                variant="body1"
                class="-muted"
              />
              <div class="-flexAlignCenter">
                <Typography
                  v-text="$t('widgets.thankYou.order.restaurant.phone')"
                  variant="body1"
                  class="h-mr-10"
                />
                <a
                  :href="`tel:${orderDetails.shop.phone}`"
                  v-text="orderDetails.shop.phone"
                />
              </div>
            </div>
          </div>
          <div class="divider h-mv-20 h-showMd" />
        </section>
      </div>
      <SectionContainer size="md">
        <div class="c-thankYou__footer h-textCenter">
          <Typography
            v-text="$t('widgets.thankYou.order.footer.title')"
            variant="subtitle2"
          />
          <Typography
            v-text="$t('widgets.thankYou.order.footer.subtitle')"
            variant="body1"
          />
          <BaseButton
            :to="$i18nRoute({name: HOME})"
            variant="solid"
            :btn-label="$t('widgets.thankYou.order.footer.btnText')"
            class="c-thankYou__footerBtn"
          />
        </div>
      </SectionContainer>
    </div>
  </Page>
</template>

<script setup>
import { computed, inject, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

import OrderSummary from '@/components/OrderSummary.vue';
import Page from '@/components/Page.vue';
import BannerImageAndTitle from '@/components/banner/BannerImageAndTitle.vue';
import { CartModel } from '@/interface/model';
import { dateDiffInDays, dateFormat } from '@/mixins/utils';
import { CART } from '@/store/types';

const store = useStore();
const route = useRoute();
const { t } = useI18n();
const { HOME } = inject('routeNames');
const { id } = route.params;

const orderDetails = computed(() => store.state.order.singleOrder);
const isDeliveryOrder = computed(() => store.getters['order/isDeliveryOrder']);
const userEmail = computed(() => store.state.order.orderData.customer.email);
const fidelityActive = computed(() => store.getters['reusable/getSetting']('fidelity_active'));
const isAuthenticated = computed(() => store.state.auth.isAuthenticated);
const customerAddress = addr => `${addr?.address}, ${addr?.city}`;

const toPoint = pt => (`${pt} pt${pt > 1 ? 's' : ''}`);

const relativeDate = orders => {
  const now = new Date();
  const splitDate = orders.delivery_date && orders.delivery_date.split('/');
  const updatedDate = orders.delivery_date ? `${splitDate[1]}/${splitDate[0]}/${splitDate[2]}` : '';
  // To avoid error if delivery_date fails to load on-time.
  const useDate = orders.delivery_date ? new Date(updatedDate) : now;

  const day = dateDiffInDays(now, useDate);

  let deliveryDate;

  switch (day) {
    case 0:
      deliveryDate = t('widgets.thankYou.order.today');
      break;
    case -1:
      deliveryDate = t('widgets.thankYou.order.yesterday');
      break;
    case 1:
      deliveryDate = t('widgets.thankYou.order.tomorrow');
      break;
    default:
      deliveryDate = dateFormat(useDate);
      break;
  }

  return `${deliveryDate} ${orders.delivery_time_start} - ${orders.delivery_time_end}`;
};

onMounted(() => {
  store.commit(`cart/${CART.SET_CART_ITEMS}`, CartModel);
  store.dispatch('order/fetchOrder', { id, userEmail: userEmail.value });
});

</script>

<style lang="scss" scoped>
@use "src/assets/styles/abstracts/mixins" as mx;

.c-thankYou {
  &__gridContainer {
    display: grid;
    grid-template-columns: 1fr;

    @include mx.bp('tablet-wide') {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &__splitContainer {
    &.-secbg {
      @include mx.use-bg-color("bg-02");
    }

    &Space {
      padding-right: 20px;
      padding-left: 20px;

      @include mx.bp('desktop-wide') {
        padding-right: 0;
        padding-left: 0;
      }
    }

    .-size {
      margin-right: 80px;
      margin-left: auto;
      width: 100%;
      @include mx.bp("desktop") {
        max-width: 31rem;
      }
    }
  }

  .-flexBox {
    @include mx.d-flex(space-between);

    &Text {
      @include mx.bp('tablet-wide') {
        width: 70%;
      }
    }

    &.-reverseCol {
      @include mx.bp('tablet', max) {
        flex-direction: column-reverse;
      }
    }
  }

  .divider {
    @include mx.use-bg-color("disabled");

    height: 1px;
  }

  &__footer {
    @include mx.d-flex(center, center, true);

    margin-top: 100px;
    margin-bottom: 100px;

    &Btn {
      margin-top: 31px;
      width: 100%;
      padding-right: 0;
      padding-left: 0;

      @include mx.bp("tablet-wide") {
        width: auto;
        padding-right: 105px;
        padding-left: 105px;
      }
    }
  }

  .-flexAlignCenter {
    @include mx.d-flex(false, center);
  }
}
</style>
